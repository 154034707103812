import theme from 'gatsby-theme-listing/src/gatsby-plugin-theme-ui'

export default {
    ...theme,
    fonts: {
        body: "'Roboto', sans-serif",
        heading: "'Space Mono', monospace",
        monospace: "'Space Mono', monospace",
      },
    fontWeights: {
        body: 300,
        heading: 700,
        bold: 700,
    },  
    colors: {
        text: '#00122c',
        background: '#fff',
        grey: '#6a84a7',
        primary: '#01cf66',
        secondary: '#01cfc9',
        muted: '#f7fafd',
    },
}